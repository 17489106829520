import React, { useState } from "react";
import "./Header.css";
import logoPoten from "./../../assets/img/logopontez.png";
import logoPotenehite from "./../../assets/img/logopontez_white.png";

import agrinutriente_img from "./../../assets/img/Catalogo_img/AGRINUTRIENTES_new.png";
import especialidades_img from "./../../assets/img/Catalogo_img/ESPECIALIDADES_new.png";
import bioestimulante_img from "./../../assets/img/Catalogo_img/bioestimulantes_new.png";
import bioprotectores_img from "./../../assets/img/Catalogo_img/bioproterores_new.png";
import correctore_img from "./../../assets/img/Catalogo_img/correctore_de_agua.png";
import coadyuvantes from "./../../assets/img/Catalogo_img/COADYUVANTES_new.png";
import hidrosolubles from "./../../assets/img/Catalogo_img/Hidrosolubles_new.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Header({ isSticky }) {
  const productos = [
    // AGRINUTRIENTES
    {
      nombre: "Agrinutriente Zn+B",
      color: "#7abe00",
      extras: "Zinc + Boro",
      generalidades:
        "El Zinc y Boro contribuye a la formación de carbohidratos y transformación de polisacáridos a almidón. También al mantenimiento de las membranas celulares, creando resistencia contra el ataque de patógenos. Mezcla perfecta para inducir floración y cuaje de fruto",
      composicion: [
        {
          componente: "Zinc (Zn)",
          porcetanje: "10.10%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "3.00%",
        },
      ],
    },
    {
      nombre: "Agrinutriente Ca+B",
      color: "#7abe00",
      extras: "Calcio + Boro",
      generalidades:
        "El Calcio y Boro son escenciales en la formación de la pared celular, ya que incrementa su rigidez, participa en el desarrollo del tubo polínico, necesario para la fecundación, es altamente asimilable contribuyendo ambos elementos al desarrollo de yemas florales, flores y frutos. Su uso disminuye los abortos de las flores e incrementa el porcentaje de amarres. ",
      composicion: [
        {
          componente: "Calcio (Ca)",
          porcetanje: "8.00%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "2.00%",
        },
      ],
    },
    {
      nombre: "Agrinutriente 17-17-17",
      color: "#7abe00",
      extras: "Fertilizantes foliar y al suelo",
      generalidades:
        "Fórmula nutricional líquida de alto contenido con Nitrógeno, Fósforo y Potasio, lo cual brinda un desarrollo óptimo e integral, ideal para corregir cualquier deficiencia nutricional básica.",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "17.00%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "17.00%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "17.00%",
        },
      ],
    },
    {
      nombre: "Agrinutriente B",
      color: "#7abe00",
      extras: "Fetilizante foliar ",
      generalidades:
        "Fórmula nutricional líquida que proporciona los requerimientos nutricionales de Boro que el cultivo necesita. Participa en la diferenciación de yemas florales y en el desarrollo del tubo polínico, además contribuye en la síntesis de piridiminas y flavonoides. Evita la mal formación del fruto, ayudando al cuaje de la flor",
      composicion: [
        {
          componente: "Boro (B)",
          porcetanje: "8.00%",
        },
        {
          componente: "Nitrógeno (N)",
          porcetanje: "2.00%",
        },
      ],
    },
    // ESPECIALIDADES
    {
      nombre: "Meister Papa",
      color: "#91016C",
      extras: "Fertilizante líquido",
      generalidades:
        "Fórmula líquida desarrollada para proporcionar parte de los elementos que requiere el cultivo de Papa, compatible con la mayoría de los fertilizantes líquidos. Único multimineral en el mercado que contiene ATP, ácidos húmicos y ácidos fúlvicos.",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "4.5%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "2.0%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "2.0%",
        },
        {
          componente: "Magnesio (MgO)",
          porcetanje: "0.2%",
        },
        {
          componente: "Azufre (S)",
          porcetanje: "1.56%",
        },
        {
          componente: "Calcio (CaO)",
          porcetanje: "0.125%",
        },
        {
          componente: "Zinc (Zn)",
          porcetanje: "0.50%",
        },
        {
          componente: "Hierro (Fe)",
          porcetanje: "0.25%",
        },
        {
          componente: "Cobre (Cu)",
          porcetanje: "0.10%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "0.15%",
        },
        {
          componente: "ATP",
          porcetanje: "10.0%",
        },
        {
          componente: "Manganeso (Mn)",
          porcetanje: "1.50%",
        },
        {
          componente: "Ácidos Húmicos",
          porcetanje: "6.0%",
        },
        {
          componente: "Ácidos Fúlvicos",
          porcetanje: "6.0%",
        },
        {
          componente: "Molibdeno (Mo)",
          porcetanje: "0.025%",
        },
      ],
    },
    {
      nombre: "Meister Maíz",
      color: "#91016C",
      extras: "Fertilizante líquido",
      generalidades:
        "Solución líquida que suministra los nutrientes y la energía necesaria para el desarrollo y el llenado del maíz. Esta fórmula es compatible con la mayoría de los fertilizantes líquidos. Es el único producto multimineral en el mercado que incluye ATP, así como ácidos húmicos y ácidos fúlvicos.",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "4.5%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "3.00%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "2.0%",
        },
        {
          componente: "Magnesio (MgO)",
          porcetanje: "0.2%",
        },
        {
          componente: "Azufre (S)",
          porcetanje: "1.56%",
        },
        {
          componente: "Calcio (CaO)",
          porcetanje: "0.125%",
        },
        {
          componente: "Zinc (Zn)",
          porcetanje: "0.50%",
        },
        {
          componente: "Hierro (Fe)",
          porcetanje: "0.25%",
        },
        {
          componente: "Cobre (Cu)",
          porcetanje: "0.10%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "0.15%",
        },
        {
          componente: "ATP",
          porcetanje: "10.0%",
        },
        {
          componente: "Manganeso (Mn)",
          porcetanje: "1.50%",
        },
        {
          componente: "Ácidos Húmicos",
          porcetanje: "6.0%",
        },
        {
          componente: "Ácidos Fúlvicos",
          porcetanje: "1.5%",
        },
        {
          componente: "Molibdeno (Mo)",
          porcetanje: "0.025%",
        },
      ],
    },
    {
      nombre: "Meister Frijol",
      color: "#91016C",
      extras: "Fertilizante líquido",
      generalidades:
        "Solución líquida que aporta los nutrientes esenciales necesarios para el cultivo de frijol, aumentando la formación de vainas y llenado del grano. Compatible con la mayoría de los fertilizantes líquidos. Único producto multimineral que contiene ATP, así como ácidos húmicos y ácidos fúlvicos.",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "4.5%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "2.0%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "2.0%",
        },
        {
          componente: "Magnesio (MgO)",
          porcetanje: "0.2%",
        },
        {
          componente: "Azufre (S)",
          porcetanje: "1.56%",
        },
        {
          componente: "Calcio (CaO)",
          porcetanje: "0.125%",
        },
        {
          componente: "Zinc (Zn)",
          porcetanje: "0.50%",
        },
        {
          componente: "Hierro (Fe)",
          porcetanje: "0.25%",
        },
        {
          componente: "Cobre (Cu)",
          porcetanje: "0.10%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "0.15%",
        },
        {
          componente: "ATP",
          porcetanje: "10.0%",
        },
        {
          componente: "Manganeso (Mn)",
          porcetanje: "1.50%",
        },
        {
          componente: "Ácidos Húmicos",
          porcetanje: "6.0%",
        },
        {
          componente: "Ácidos Fúlvicos",
          porcetanje: "1.5%",
        },
        {
          componente: "Molibdeno (Mo)",
          porcetanje: "0.025%",
        },
      ],
    },
    {
      nombre: "Meister Café",
      color: "#91016C",
      extras: "Fertilizante Líquido",
      generalidades:
        "Se ha creado una fórmula líquida especializada que suministra los nutrientes necesarios para el cultivo de café. Además, es el único producto multimineral que contiene ATP, así como ácidos húmicos y ácidos fúlvicos. Estos componentes adicionales proporcionan beneficios únicos para promover un crecimiento saludable y mejorar la calidad del café cultivado.",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "4.5%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "2.0%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "2.0%",
        },
        {
          componente: "Magnesio (MgO)",
          porcetanje: "0.2%",
        },
        {
          componente: "Azufre (S)",
          porcetanje: "1.56%",
        },
        {
          componente: "Calcio (CaO)",
          porcetanje: "0.125%",
        },
        {
          componente: "Zinc (Zn)",
          porcetanje: "0.50%",
        },
        {
          componente: "Hierro (Fe)",
          porcetanje: "0.25%",
        },
        {
          componente: "Cobre (Cu)",
          porcetanje: "0.10%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "0.15%",
        },
        {
          componente: "ATP",
          porcetanje: "10.0%",
        },
        {
          componente: "Manganeso (Mn)",
          porcetanje: "1.50%",
        },
        {
          componente: "Ácidos Húmicos",
          porcetanje: "6.0%",
        },
        {
          componente: "Ácidos Fúlvicos",
          porcetanje: "1.5%",
        },
        {
          componente: "Molibdeno (Mo)",
          porcetanje: "0.025%",
        },
      ],
    },
    {
      nombre: "Meister Ajonjolí",
      color: "#91016C",
      extras: "Fertilizante líquido",
      generalidades:
        "Fórmula líquida desarrollada para proporcionar parte de los elementos que requiere el cultivo de ajonjolí, compatible con la mayoría de los fertilizantes líquidos, lo que facilita el hacer mezclas o fórmulas adecuadas a las diferentes etapas fenológicas de los cultivos.",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "4.5%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "2.0%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "2.0%",
        },
        {
          componente: "Magnesio (MgO)",
          porcetanje: "0.2%",
        },
        {
          componente: "Azufre (S)",
          porcetanje: "1.56%",
        },
        {
          componente: "Calcio (CaO)",
          porcetanje: "0.125%",
        },
        {
          componente: "Zinc (Zn)",
          porcetanje: "0.50%",
        },
        {
          componente: "Hierro (Fe)",
          porcetanje: "0.25%",
        },
        {
          componente: "Cobre (Cu)",
          porcetanje: "0.10%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "0.15%",
        },
        {
          componente: "ATP",
          porcetanje: "10.0%",
        },
        {
          componente: "Manganeso (Mn)",
          porcetanje: "1.50%",
        },
        {
          componente: "Ácidos Húmicos",
          porcetanje: "6.0%",
        },
        {
          componente: "Ácidos Fúlvicos",
          porcetanje: "1.5%",
        },
        {
          componente: "Molibdeno (Mo)",
          porcetanje: "0.025%",
        },
      ],
    },
    {
      nombre: "Meister Plus",
      color: "#91016C",
      extras: "Fertilizante líquido",
      generalidades:
        "Formulación líquida se puede aplicar en sistemas de fertirriego o en el follaje. Contiene macro y microelementos enriquecidos con ATP, ácidos húmicos y fúlvicos. Proporciona a la planta una equilibrada nutrición, corrige deficiencias nutricionales debido a su rápida asimilación, facilitando su absorción foliar y radicular",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "4.5%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "2.0%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "2.0%",
        },
        {
          componente: "Magnesio (MgO)",
          porcetanje: "0.2%",
        },
        {
          componente: "Azufre (S)",
          porcetanje: "1.56%",
        },
        {
          componente: "Calcio (CaO)",
          porcetanje: "0.125%",
        },
        {
          componente: "Zinc (Zn)",
          porcetanje: "0.50%",
        },
        {
          componente: "Hierro (Fe)",
          porcetanje: "0.25%",
        },
        {
          componente: "Cobre (Cu)",
          porcetanje: "0.10%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "0.15%",
        },
        {
          componente: "ATP",
          porcetanje: "10.0%",
        },
        {
          componente: "Manganeso (Mn)",
          porcetanje: "1.50%",
        },
        {
          componente: "Ácidos Húmicos",
          porcetanje: "6.0%",
        },
        {
          componente: "Ácidos Fúlvicos",
          porcetanje: "1.5%",
        },
        {
          componente: "Molibdeno (Mo)",
          porcetanje: "0.025%",
        },
      ],
    },
    {
      nombre: "8-24-0",
      color: "#91016C",
      extras: "Fertilizante al suelo",
      generalidades:
        "Fertilizante líquido altamente beneficioso para la planta, especialmente en la formación de compuestos fosforados. Además, esta fórmula nutricional proporciona una cantidad significativa de Nitrógeno y Fósforo necesarios para el cultivo. Es importante destacar que este producto se utiliza exclusivamente en aplicaciones al suelo, brindando los nutrientes esenciales para un crecimiento óptimo de las plantas.",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "10.00%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "30.00%",
        },
      ],
      avertensia: "Úsese exclusivamente en aplicaciones al suelo",
    },
    {
      nombre: "Humato De Calcio",
      color: "#91016C",
      extras: "Fertilizante Foliar",
      generalidades:
        "Fórmula líquida nutricional con una solubilidad del 100% en agua, especialmente desarrollada para proporcionar una fuente de Calcio a los cultivos. Este producto también juega un papel crucial en la regulación de cationes como el Potasio, Magnesio y Sodio, lo que resulta en una nutrición equilibrada para las plantas. Además, este fertilizante contribuye al engrosamiento de las paredes celulares, fortaleciendo así la resistencia de los cultivos frente a plagas y enfermedades.",
      composicion: [
        {
          componente: "Calcio (CaO)",
          porcetanje: "20.00%",
        },
        {
          componente: "Húmicos",
          porcetanje: "12.00%",
        },
      ],
    },
    {
      nombre: "Humato De Magnesio",
      color: "#91016C",
      extras: "Fertilizante foliar",
      generalidades:
        "Contiene una alta concentración de Magnesio (Mg) acompañado con ácidos húmicos, formulado para corregir las deficiencias de Magnesio en la planta. El Magnesio forma parte de la molécula principal de la clorofila, participando directamente en la fotosíntesis, asimismo ayuda en la formación y movimiento de azúcares en la planta.",
      composicion: [
        {
          componente: "Magnesio (MgO)",
          porcetanje: "12.00%",
        },
        {
          componente: "Ácidos Húmicos",
          porcetanje: "8.00%",
        },
      ],
    },
    {
      nombre: "Potenz-K",
      color: "#91016C",
      extras: "Potasio líquido",
      generalidades:
        "Fertilizante líquido potásico que esta libre de Cloro, Azufre y Nitrógeno. Auxilia en el transporte de los nutrientes en la planta funcionando como vehículo, mejorando el contenido de azúcar de los frutos, tales como grados Brix, relación azúcar/ácido, intensidad de pigmentos como Xantofilas y Caroteno, aumenta el peso y consistencia del fruto.",
      composicion: [
        {
          componente: "Potasio (K₂O)",
          porcetanje: "25.00%",
        },
        {
          componente: "Adenosin Trifosfato (ATP)",
          porcetanje: "10.00%",
        },
      ],
    },
    {
      nombre: "Nitrato De Zinc",
      color: "#91016C",
      extras: "Fertilizante foliar y al suelo",
      generalidades:
        "Presentación líquida y su sencilla aplicación, este producto es efectivo en la prevención, combate y corrección de deficiencias de Zinc en las plantas. Su alta asimilación por parte de la planta permite influir de manera significativa en la absorción de este mineral esencial, logrando un equilibrio adecuado con otros nutrientes",
      composicion: [
        {
          componente: "Zinc (Zn)",
          porcetanje: "19.00%",
        },
        {
          componente: "Nitrógeno (N)",
          porcetanje: "8.00%",
        },
      ],
    },
    {
      nombre: "Potenz Brixx",
      color: "#91016C",
      extras: "Madurante concentrado de azucares",
      generalidades:
        "Producto diseñado específicamente para controlar y ralentizar el crecimiento y la elongación de los tallos en diversos cultivos. Esta acción tiene como resultado una mayor concentración de azúcares y un equilibrio en el tamaño de los componentes de la planta. En el caso particular de la caña de azúcar, es de vital importancia inhibir el crecimiento longitudinal de los tallos para favorecer la acumulación de sacarosa, lo cual se logra mediante el uso de este producto",
      composicion: [
        {
          componente: "Potasio (K₂O)",
          porcetanje: "25.00%",
        },
        {
          componente: "Adenosin Trifosfato (ATP)",
          porcetanje: "10.00%",
        },
        {
          componente: "Cloruro de Mepiquat",
          porcetanje: "0.50%",
        },
      ],
    },
    {
      nombre: "Más Caña",
      color: "#91016C",
      extras: "Fertilizante Liquido",
      generalidades:
        "Formulación líquida que posee una balanceada composición que coincide en diferentes principios fisiológicos para mejorar los procesos metabólicos de la caña de azúcar. Induce a un acelerado desarrollo de los mismos, repercutiendo en su altura y grosor.",
      composicion: [
        {
          componente: "Aminoácidos",
          porcetanje: "8.00%",
        },
        {
          componente: "Zinc (Zn)",
          porcetanje: "7.12%",
        },
        {
          componente: "Nitrógeno (N)",
          porcetanje: "3.76%",
        },
        {
          componente: "Magnesio (MgO)",
          porcetanje: "0.50%",
        },
        {
          componente: "Hierro (Fe)",
          porcetanje: "0.0625%",
        },
        {
          componente: "Manganeso (Mn)",
          porcetanje: "0.375%",
        },
        {
          componente: "Cobre (Cu)",
          porcetanje: "0.025%",
        },
        {
          componente: "Calcio (CaO)",
          porcetanje: "0.031%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "0.037%",
        },
        {
          componente: "Molibdeno (Mo)",
          porcetanje: "0.006%",
        },
      ],
    },
    {
      nombre: "Fost Alexin-K",
      color: "#91016C",
      extras: "Fertilizante foliar",
      generalidades:
        "Elaborado en forma líquida soluble con un 40% de Potasio Fosfático, este principio activo posee un marcado carácter sistémico tanto ascendente por el Xilema como descendente por el Floema. Controla enfermedades como tizón tardío y mildius en diversos cultivos al estimular los procesos naturales de defenesa de las plantas",
      composicion: [
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "18.00%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "22.00%",
        },
      ],
    },
    {
      nombre: "Microplus",
      color: "#91016C",
      extras: "Fertilizante foliar y al suelo",
      generalidades:
        "Fórmula nutricional soluble desarrollada para proporcionar parte de los micro-elementos que requiere el cultivo. Compatible con la mayoría de los fertilizantes líquidos, lo que facilita el hacer mezclas o fórmulas adecuadas a las diferentes etapas fenológicas de los cultivos.",
      composicion: [
        {
          componente: "Hierro (Fe)",
          porcetanje: "5.40%",
        },
        {
          componente: "Zinc (Zn)",
          porcetanje: "4.00%",
        },
        {
          componente: "Magnesio (MgO)",
          porcetanje: "2.60%",
        },
        {
          componente: "Manganeso (Mn)",
          porcetanje: "1.55%",
        },
        {
          componente: "Cobre (Cu)",
          porcetanje: "1.45%",
        },
        {
          componente: "Molibdeno (Mo)",
          porcetanje: "0.50%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "2.30%",
        },
        {
          componente: "Aminoácidos",
          porcetanje: "2.62%",
        },
        {
          componente: "Azufre (S)",
          porcetanje: "8.31%",
        },
      ],
    },
    {
      nombre: "Quelatt Fe",
      color: "#91016C",
      extras: "Fertilizante foliar y al suelo",
      generalidades:
        "Fertilizante a base de hierro quelatado (EDTA Fe) desarrollado para corregir, prevenir y curar las deficiencias del micronutriente Hierro (Fe) en los cultivos. Es un polvo hidrosoluble que contiene un amplio rango de estabilidad de pH (4 a 8) en la solución, permitiendo una forma efectiva para el tratamiento de la clorosis férrica.",
      composicion: [
        {
          componente: "Hierro",
          porcetanje: "13.00%",
        },
        {
          componente: "Agente EDTA",
          porcetanje: "52.50%",
        },
      ],
    },
    {
      nombre: "10-34-0",
      color: "#91016C",
      extras: "Fertilizante líquido",
      generalidades:
        "Fertilizante líquido fosforado que participa ampliamente en  la construcción de los compuestos fosforados de la planta,  encargados del transporte y almacenamiento de la energía  necesaria para realizar los procesos vitales permitiendo un  crecimiento vigoroso del sistema radicular.",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "10.00%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "34.00%",
        },
      ],
    },
    // BIOESTIMULANTES
    {
      nombre: "Atp-K",
      color: "#F4C241",
      extras: "Fuente de energía en condiciones extremas",
      generalidades:
        "Este producto líquido contiene una fuente de ATP de rápida asimilación por los cultivos. El ATP, una molécula esencial para el almacenamiento y transferencia de energía en las células, ayuda a evitar el agotamiento energético durante etapas críticas como la floración y la fructificación. Además, mejora el funcionamiento metabólico de las plantas y las ayuda a resistir condiciones de estrés. Este producto proporciona un impulso energético a los cultivos, mejorando su rendimiento y capacidad de adaptación.",
      composicion: [
        {
          componente: "Adenosín Trifosfato (ATP)",
          porcetanje: "30.00%",
        },
        {
          componente: "Nitrógeno (N)",
          porcetanje: "1.5%",
        },
        {
          componente: "Potasio (K)",
          porcetanje: "5.00%",
        },
      ],
    },
    {
      nombre: "Master Flower",
      color: "#F4C241",
      extras: "Bioestimulante para floración y cuaje",
      generalidades:
        "Poderosa solución que induce procesos reproductivos en las plantas, especialmente la floración. Su contenido de algas marinas, aminoácidos y ATP lo convierten en un excelente promotor de la producción de flores. Además, este producto es efectivo para reducir el estrés causado por sequías, inundaciones y falta de horas de luz, proporcionando directamente la energía necesaria para los procesos metabólicos esenciales relacionados con la producción y desarrollo de flores.",
      composicion: [
        {
          componente: "Adenosín Trifosfato (ATP)",
          porcetanje: "17.00%",
        },
        {
          componente: "Algas Marinas",
          porcetanje: "3.00%",
        },
        {
          componente: "Aminoácidos",
          porcetanje: "2.00%",
        },
        {
          componente: "Nitrógeno (N)",
          porcetanje: "1.80%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "0.80%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "0.80%",
        },
        {
          componente: "Ácidos Húmicos",
          porcetanje: "2.40%",
        },
        {
          componente: "Ácidos Fúlvicos",
          porcetanje: "0.60%",
        },

        {
          componente: "Elementos menores",
          porcetanje: "",
        },
      ],
    },
    {
      nombre: "Potenzamin 50",
      color: "#F4C241",
      extras: "Bioestimulante orgánico",
      generalidades:
        "Esta formulación contiene 18 aminoácidos escenciales levógiros libres de origen vegetal, que representan el 50% de su composición. Estos aminoácidos actúan como iniciadores de la producción de fitohormonas en las plantas, lo que facilita la utilización de nutrientes y estimula diversas reacciones fisiológicas. Además, esta formulación ayuda a combatir el estrés causado por diferentes factores, proporcionando la energía necesaria para un óptimo desarrollo del cultivo.",
      composicion: [
        {
          componente: "Aminoácidos",
          porcetanje: "50.00%",
        },
      ],
    },
    {
      nombre: "Root Drive",
      color: "#F4C241",
      extras: "Activador fisiológico de raices y crecimiento",
      generalidades:
        "Este producto actúa mediante la formación de aminoácidos esenciales que son precursores clave en la activación de los puntos de crecimiento radicular. Además, este producto ayuda a la recuperación de las plantas en condiciones de estrés provocadas por sequías o exceso de humedad.",
      composicion: [
        {
          componente: "Adenosín Trifosfato (ATP)",
          porcetanje: "15.00%",
        },
        {
          componente: "Aminoácidos",
          porcetanje: "2.00%",
        },
        {
          componente: "Ácidos Húmicos",
          porcetanje: "7.50%",
        },
        {
          componente: "Ácidos Húmicos",
          porcetanje: "7.50%",
        },
        {
          componente: "Ácidos Carboxilicos",
          porcetanje: "15.00%",
        },
      ],
    },
    {
      nombre: "Alpromar 30 SL",
      color: "#F4C241",
      extras: "Bioestimulante líquido",
      generalidades:
        "Producto que permite al cultivo alcanzar un mayor desarrollo foliar, mayor producción, mejor absorción de nutrientes; por lo que resulta un complemento perfecto en programas de fertilización.",
      composicion: [
        {
          componente: "Algas Marinas",
          porcetanje: "30.00%",
        },
        {
          componente: "Aminoácidos",
          porcetanje: "18.60%",
        },
        {
          componente: "ATP+K",
          porcetanje: "18.60%",
        },
        {
          componente: "Nitrógeno (N)",
          porcetanje: "18.60%",
        },
        {
          componente: "Magnesio (Mg)",
          porcetanje: "0.33%",
        },
        {
          componente: "Azufre (S)",
          porcetanje: "1.50%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "0.60%",
        },
        {
          componente: "Zinc (Zn)",
          porcetanje: "0.30%",
        },
        {
          componente: "Molibdeno (Mo)",
          porcetanje: "0.075%",
        },
        {
          componente: "Hierro (Fe)",
          porcetanje: "0.30%",
        },
        {
          componente: "Cobalto (Co)",
          porcetanje: "0.006%",
        },
        {
          componente: "Niquel (Ni)",
          porcetanje: "0.06%",
        },
        {
          componente: "EDTA",
          porcetanje: "0.60%",
        },
        {
          componente: "Carbono",
          porcetanje: "4.689%",
        },
      ],
    },
    // {
    //   nombre: "Aminoácidos 80%",
    //   color: "#F4C241",
    //   extras: "Bioestimulante orgánico",
    //   generalidades:
    //     "Formulación con un 80% de aminoácidos levógiros de origen vegetal. Estos iniciadores de fitohormonas estimulan reacciones fisiológicas, combaten el estrés y proveen energía para un óptimo desarrollo del cultivo. Mejora la absorción de nutrientes y promueve el crecimiento saludable de las plantas.",
    //   composicion: [
    //     {
    //       componente: "Aminoácidos",
    //       porcetanje: "80.00%",
    //     },
    //   ],
    // },
    {
      nombre: "15-15-15 Master",
      color: "#F4C241",
      extras: "Fertilizante hidrosoluble",
      generalidades:
        "Formulación nutricional hidrosoluble desarrollada para proporcionar parte de Nitrógeno, Fósforo y Potasio que requiere el cultivo. Compatible con la mayoría de los productos agrícolas de uso común, lo que facilita el hacer mezclas o fórmulas adecuadas a las diferentes etapas fenológicas de los cultivos.",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "15.00%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "15.00%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "15.00%",
        },
        {
          componente: "Aminoácidos (K₂O)",
          porcetanje: "2.00%",
        },
        {
          componente: "Algas Marinas",
          porcetanje: "1.00%",
        },
        {
          componente: "Áciddos Húmicos y Fúlvicos",
          porcetanje: "5.00%",
        },
        {
          componente: "Elementos menores",
          porcetanje: "5.00%",
        },
        {
          componente: "Ácido IndolButírico",
          porcetanje: "0.03%",
        },
      ],
    },
    {
      nombre: "Microamin",
      color: "#F4C241",
      extras: "Bioestimulante completo",
      generalidades:
        "Formulación que contiene microelementos esenciales y aminoácidos, para etapas fisiológicas especificas tales como generación de brotes, floración, cuaje y llenado de fruto. Alta concentración de aminoácidos que ayuda a combatir los diferentes tipos de estrés mejora la calidad de polen, mejora el sistema inmunológico.",
      composicion: [
        {
          componente: "Aminoácidos",
          porcetanje: "59.00%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "2.00%",
        },
        {
          componente: "Zinc (Zn)",
          porcetanje: "1.00%",
        },
        {
          componente: "Hierro (Fe)",
          porcetanje: "1.00%",
        },
        {
          componente: "Cobalto (Co)",
          porcetanje: "0.108%",
        },
        {
          componente: "Niquel (Ni)",
          porcetanje: "0.20%",
        },
        {
          componente: "Molibdeno (Mo)",
          porcetanje: "0.25%",
        },
        {
          componente: "Magnesio (Mg)",
          porcetanje: "1.104%",
        },
        {
          componente: "Azufre (S)",
          porcetanje: "2.0578%",
        },
      ],
    },
    {
      nombre: "Alpromar 100",
      color: "#F4C241",
      extras: "Bioestimulante orgánico",
      generalidades:
        "Bioestimulante en polvo soluble en agua, naturalmente derivado del alga (Ascophyllum nodosum). Es un producto no tóxico ni dañino al medio ambiente. Rico en microelementos y proveedor natural de Citoquininas y Giberelinas, Ácido Alginico y Aminoácidos. Promueve el enraizamiento, crecimiento de tallo y follaje, así como floración y frutos, excelentes reductores de estrés en trasplantes o circunstancias de factores bióticos y abióticos",
      composicion: [
        {
          componente: "Extracto de Algas Marinas",
          porcetanje: "99.94%",
        },
        {
          componente: "Citoquininas y Giberelinas",
          porcetanje: "0.06%",
        },
      ],
    },
    // BIOPROTECTORES
    {
      nombre: "Fange 20 SL",
      color: "#7F7F7F",
      extras: "Insecticida, acaricida con acción repelente y ovicida",
      generalidades:
        "Es un insecticida elaborado a partir de compuestos orgánicos como la Azadiractina y ácido Alílico. Su función, trabajar como insecticida y acaricida, para poder inducir una acción de repelencia y por ingestión de los insectos interferentes de los campos de cultivo; controlando así los estados de: Huevos, ninfa o larva y adultos.",
      composicion: [
        {
          componente: "Extracto de Ajo",
          porcetanje: "10.00%",
        },
        {
          componente: "Aceite de Neem",
          porcetanje: "10.00%",
        },
      ],
    },
    {
      nombre: "K-Óleo",
      color: "#7F7F7F",
      extras: "Insecticida de contacto preventivo a base de jabón potásico",
      generalidades:
        "Producto orgánico que actua por contacto. Provocando asfixia y por consecuencia la muerte del insecto. Reblandece la cutícula de los insectos provocando su muerte, efectivo para control de pulgón, mosca blanca, araña roja, cochinillas, ácaros e insectos de cutícula blanda, recomendado para cultivos frutales y ornamentales",
      composicion: [
        {
          componente: "Potasio (K₂O)",
          porcetanje: "10.10%",
        },
      ],
    },
    {
      nombre: "Omicron Bio 15 SL",
      color: "#7F7F7F",
      extras: "Desinfectante agrícola",
      generalidades:
        "Desinfectante de suelos agrícolas, diseñado para la supresión de enfermedades fungosas y bacterianas, causadas por microorganismos fitopatógenos que habitan dentro del microbiota del suelo. Apto para cultivos con certificados orgánicos y de exportación, dado que no genera trazabilidad.",
      composicion: [
        {
          componente: "Ácido Peracético",
          porcetanje: "15.00%",
        },
      ],
    },
    {
      nombre: "Omicron BF 2000",
      color: "#7F7F7F",
      extras: "Fungicida",
      generalidades:
        "Fungicida de contacto que ha sido desarrollado para un amplio control de enfermedades en cultivos. Actúa preventiva y curativamente eliminando los hongos y bacterias presentes y previniendo la reinfección por los mismos.",
      composicion: [
        {
          componente: "Amonio Cuaternario",
          porcetanje: "12.00%",
        },
        {
          componente: "Alkil Aril Poliglicol Eter",
          porcetanje: "10.00%",
        },
        {
          componente: "Alcohol Láurico",
          porcetanje: "6.00%",
        },
        {
          componente: "Alcohol Etilico",
          porcetanje: "10.00%",
        },
        {
          componente: "Acidulante Orgánico",
          porcetanje: "2.00%",
        },
        {
          componente: "Agua tratada y clorada",
          porcetanje: "60.00%",
        },
      ],
    },
    {
      nombre: "Potenz Neen",
      color: "#7F7F7F",
      extras: "Insecticida orgánico",
      generalidades: `Poderoso insecticida orgánico contiene una alta
        concentración de "Azadiractina", altamente efectivo en el
        control de plagas en cultivos hortícolas, frutícolas,
        ornamentales y forestales. Controla una amplia gama de
        plagas, como áfidos, trips, ácaros, gusanos, chinches,
        pulgones y larvas de mosca blanca. Este insecticida no es
        dañino para las plantas y no deja trazabilidad.
        `,
      composicion: [
        {
          componente: "Aceite de Extracto de Neem",
          porcetanje: "98.00%",
        },
      ],
    },
    {
      nombre: "Karajo Ec",
      color: "#7F7F7F",
      extras:
        "Insecticida y acaricida a base de aceite de Neem y Extracto de Karanja",
      generalidades:
        "Combinación de extracto orgánico de Neem y Karanja que genera una excelente efectividad para el control, reducción y erradicación de las plagas en los cultivos agrícolas. Se puede utilizar hasta el mismo día de la cosecha, no presenta trazas ni residuos.",
      composicion: [
        {
          componente: "Extracto de Karanja",
          porcetanje: "42.5%",
        },
        {
          componente: "Extracto de Neem",
          porcetanje: "42.5%",
        },
      ],
    },
    {
      nombre: "Potenzsil",
      color: "#7F7F7F",
      extras: "Fertilizante de Silicato de Potasio líquido",
      generalidades:
        "Silicato de Potasio líquido de alta concentración que mejora la estructura celular, mayor resistencia a los ataques de patógenos y de insectos ya que forma una barrera física en el tejido epidérmico. Esta formulado para incrementar las defensas de crecimiento, protección natural contra el estrés, al ataque de hongo y el ataque de insectos chupadores esta comprobado que el uso de Potenzsil en gramineas aumenta drasticamente la producción.",
      composicion: [
        {
          componente: "Silicato de Potasio",
          porcetanje: "52.52%",
        },
      ],
    },
    {
      nombre: "Lange Leben",
      color: "#7F7F7F",
      extras: "Prolongador de vida de anaquel",
      generalidades:
        "Formulación líquida que actúa disminuyendo la respiración celular aletargando el proceso de maduración de los frutos. Mejora en forma significativa la adecuada coagulación de las pectinas tanto del fruto como de su cáscara mejorando la turgencia de las células retardando el proceso de envejecimiento y oxidación natural de los mismos.",
      composicion: [
        {
          componente: "Polímero Vegetal",
          porcetanje: "20.00%",
        },
        {
          componente: "Polisacáridos",
          porcetanje: "15.00%",
        },
      ],
    },
    {
      nombre: "Karanja 85 SL",
      color: "#7F7F7F",
      extras: "Insecticida orgánico",
      generalidades: `El extracto de Karanja con su potente ingrediente activo
      "Karanjin" ofrece una solución efectiva y segura para el
      manejo de plagas en los cultivos, es seguro utilizarlo en los
      cultivos hasta el mismo día de la cosecha, ya que no deja
      ningún tipo de trazas ni residuos.`,
      composicion: [
        {
          componente: "Extracto de Karanja",
          porcetanje: "85.00%",
        },
      ],
    },
    {
      nombre: "K-Óleo + Canela",
      color: "#7F7F7F",
      extras: "Insecticida y repelente orgánico",
      generalidades:
        "Producto orgánico que actua por contacto. Provocando asfixia y por consecuencia la muerte del insecto. Reblandece la cutícula de los insectos provocando su muerte, efectivo para control de pulgón, mosca blanca, araña roja, cochinillas, ácaros e insectos de cutícula blanda, recomendado para cultivos frutales y ornamentales. Por su combinación con extracto de Canela mejora el efecto repelente.",
      composicion: [
        {
          componente: "Extracto de Canela",
          porcetanje: "2.00%",
        }
      ],
    },
    //Correctores De Agua Y Suelo
    {
      nombre: "Abland SL",
      color: "#05A0EE",
      extras: "Acondicionador de agua con propiedades humectantes",
      generalidades:
        "Abland SL esta especialmente diseñado para reducir la dureza del agua y regular el pH de la misma. Evitando la hidrólisis del ingrediente activo, aumentando su efectividad en las aplicaciones de herbicidas, fungicidas, bactericidas e insecticidas. Además tiene propiedades de humectación, dispersión y antiespumantes.",
      composicion: [
        {
          componente: "Agente Fosfórico",
          porcetanje: "20.00%",
        },
        {
          componente: "Agente Quelante (EDTA)",
          porcetanje: "50.00%",
        },
        {
          componente: "Lauril Sulfato de Sodio",
          porcetanje: "15.00%",
        },
      ],
    },
    {
      nombre: "Abland SL 75",
      color: "#05A0EE",
      extras: "Regulador de pH y dureza",
      generalidades:
        "Formulación para condiciones de media-alta de dureza, sin afectar drásticamente los valores de pH del agua. Promueve la estabilidad en mezclas por periodos hasta de 72 horas, evitando la hidrólisis del ingrediente activo en mezcla.",
      composicion: [
        {
          componente: "Agente Quelante",
          porcetanje: "50.00%",
        },
        {
          componente: "Regulador de pH",
          porcetanje: "20.00%",
        },
        {
          componente: "Buferizante",
          porcetanje: "10.00%",
        },
      ],
    },
    {
      nombre: "Abland N 70 SL",
      color: "#05A0EE",
      extras: "Reductor de dureza y buferizador de pH",
      generalidades:
        "Formulación para condiciones de agua con dureza media-alta, afectando la dureza sin modificar drásticamente su pH, con resultados finales en un rango de pH entre 5 a 6 dependiendo de la dosis. Promueve la estabilidad en mezclas por periodos hasta de 72 horas, evitando la hidrólisis del ingrediente activo.",
      composicion: [
        {
          componente: "EDTA sal disodica",
          porcetanje: "70.00%",
        },
      ],
    },
    {
      nombre: "Abland P 80 SL",
      color: "#05A0EE",
      extras: "Regulador de pH y corrector de durezas extremas",
      generalidades:
        "Corrector líquido de dureza y pH de alta concentración para aguas duras y alcalinas extremas, especialmente formulado para la aplicación de agroquímicos, optimizando el efecto de estos. Evita que se hidrolice los agroquímicos por exposición a un pH y dureza inadecuados. Mejora su eficiencia en el momento de la aplicación reduciendo costos por su alta concentración.",
      composicion: [
        {
          componente: "EDTA sal disodica",
          porcetanje: "80.00%",
        },
      ],
    },
    {
      nombre: "Abland 85 SL",
      color: "#46947D",
      extras: "Humectante, Adherente, antiespumante y dispersante",
      generalidades:
        "Corrector de agua que mejora la eficacia de los productos fitosanitarios. Reduce la dureza del agua al eliminar sales como carbonatos y bicarbonatos de calcio y magnesio, ajusta el pH para evitar la descomposición de los agroquímicos y mejora la distribución del producto con propiedades humectantes, antiespumantes y dispersantes. Esto asegura una aplicación más eficiente y efectiva.",
      composicion: [
        {
          componente: "EDTA (Agente Quelante)",
          porcetanje: "50.00%",
        },
        {
          componente: "Acido fosforico",
          porcetanje: "20.00%",
        },
        {
          componente: "Lauril sulfato de sodio",
          porcetanje: "15.00%",
        },
        {
          componente: "Inertes",
          porcetanje: "15.00%",
        },
      ],
    },
    {
      nombre: "Inditur-Acid 45 SL",
      color: "#05A0EE",
      extras: "Corrector de pH y surfactante con indicador colorimétrico",
      generalidades:
        "Acidificante, dispersante, antiespumante, humectante y corrector para aguas alcalinas las cuales se utilizan para diluir y asperjar diferentes agroquímicos en los cultivos, optimizando el efecto de esto. Minimiza considerablemente la hidrólisis o degradación de la mayoría de los pesticidas de uso actual por un pH inadecuado.",
      composicion: [
        {
          componente: "Ácido Fosfórico",
          porcetanje: "25.00%",
        },
        {
          componente: "Alcohol Dodecilico",
          porcetanje: "10.00%",
        },
        {
          componente: "Lauril Sulfato de Sodio",
          porcetanje: "10.00%",
        },
      ],
    },
    {
      nombre: "HCA-25",
      color: "#05A0EE",
      extras: "Ácidos húmicos y fúlvicos",
      generalidades:
        "Quelante orgánico a base de ácidos húmicos y fúlvicos que nos ayuda a corregir y estabilizar la mayoría de las mezclas de nutrientes, favoreciendo una buena relación de ácidos y bases del suelo. En aplicaciones al suelo facilita la absorción de los macronutrientes y micronutrientes por medio de las raíces de las plantas y en aplicaciones vía foliar en mezcla con fertilizantes foliares facilita el ingreso a la planta por medio de las ceras y cutícula de la hoja.",
      composicion: [
        {
          componente: "Húmicos",
          porcetanje: "25.00%",
        },
        {
          componente: "Fúlvicos",
          porcetanje: "15.00%",
        },
      ],
    },
    {
      nombre: "Aquamild 40 SL",
      color: "#05A0EE",
      extras: "Acondicionador de suelos con alto contenido de sales",
      generalidades:
        "Acondicionador y corrector para suelos con alto contenido de sales, recomendado para aplicaciones al suelo, nunca foliar. Combate problemas de sodicidad y alcalinidades causadas por exceso de sodio o calcio. También contribuye a evitar sedimentaciones y deterioros en los sistemas de fertirrigación, sin ocasionar problemas de taponamientos en boquillas o emisores.",
      composicion: [
        {
          componente: "Ácido inorganico",
          porcetanje: "40.00%",
        },
        {
          componente: "Ácido orgánico",
          porcetanje: "25.00%",
        },
      ],
    },
    {
      nombre: "Descomp",
      color: "#05A0EE",
      extras: "Descompactador de suelos",
      generalidades:
        "Producto líquido especialmente formulado para mejorar la estructura del suelo, favoreciendo una mejor oxigenación y disponibilidad de nutrientes. Desflocula el suelo, incrementando la dispersión de las partículas que lo forman, logrando una mayor superficie que retenga la humedad mejorando a su vez el drenaje, evitando el encharcamiento excesivo.",
      composicion: [
        {
          componente: "Desfloculante",
          porcetanje: "30.00%",
        },
        {
          componente: "Agente Quelatante",
          porcetanje: "10.00%",
        },
        {
          componente: "Tensoactivo",
          porcetanje: "10.00%",
        },
        {
          componente: "Diluyente",
          porcetanje: "50.00%",
        },
      ],
    },
    {
      nombre: "Humi K80",
      color: "#05A0EE",
      extras: "Ácidos húmicos y fúlvicos",
      generalidades:
        "Mejorador de suelos con contenido de ácidos húmicos, fúlvicos y potasio, pertenece a los productos basados en la sal potásica de los ácidos húmicos (Humatos potásicos) extraídos de Leonardita o carbones similares",
      composicion: [
        {
          componente: "Húmicos",
          porcetanje: "65.00%",
        },
        {
          componente: "Fúlvicos",
          porcetanje: "15.00%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "10.00%",
        },
      ],
    },
    // Coadyuvantes
    {
      nombre: "Spander 100 SL",
      color: "#46947D",
      extras: "Dispersante, pentrante, humectante y surfactante",
      generalidades:
        "Coadyuvante siliconado, que mejora las características de disperción, penetración, humectación y que actua como surfactante. Mejora el área de cobertura de las soluciones de aspersión que involucran insecticidas, fungicidas, bactericidas, herbicidas y foliares, permitiendo que los ingredientes activos de los agroquímicos sean más eficientes.",
      composicion: [
        {
          componente: "Polieter Polimetilsiloxano Copolimero",
          porcetanje: "95.00%",
        },
        {
          componente: "Poliglicol Eter Alcohol Graso",
          porcetanje: "3.00%",
        },
        {
          componente: "Dimetil Polixiloxano",
          porcetanje: "2.00%",
        },
      ],
    },
    {
      nombre: "W-30 SL",
      color: "#46947D",
      extras: "Adherente, dispersante, penetrante y  antiespumante",
      generalidades:
        "Adherente, penetrante, dispersante y antiespumante formulado para potencializar las aplicaciónes de agroquímicos foliares y al suelo. A diferencia de otros productos similares, este no causa efecto lupa en el follaje de las plantas, lo que previene cualquier posibilidad de quemaduras.",
      composicion: [
        {
          componente: "Alcohol Graso Polyglycol Eter",
          porcetanje: "28.3%",
        },
        {
          componente: "Dimetil Polixiloxano",
          porcetanje: "1.7%",
        },
      ],
    },
    {
      nombre: "Film Kover 83 EC",
      color: "#46947D",
      extras: "Adherente, penetrante, dispersante y emulsionante",
      generalidades:
        "Coadyuvante no iónico elaborado a partir de Resina de Pino el cual aumenta la vida del ingrediente activo de los agroquímicos protectantes, formando una película pegajosa y elástica que mantiene firmemente adheridos los plaguicidas en el follaje del cultivo por varios días.",
      composicion: [
        {
          componente: "Resina de pino etoxilada",
          porcetanje: "83.00%",
        },
      ],
    },
    {
      nombre: "Ps - Max",
      color: "#46947D",
      extras: "Coadyuvante, pesante, antideriva",
      generalidades:
        "Coadyuvante agrícola con propiedades adherentes, antideriva que reduce las pérdidas de agroquímicos en las aplicaciones, ocasionadas por vientos intensos volviendo la gota más pesada y protección a la molécula por fotodegradación. Puede ser utilizado para aplicaciones terrestres y aéreas.",
      composicion: [
        {
          componente: "Dodecil benceno sulfonato de trietanolamina",
          porcetanje: "35.00%",
        },
        {
          componente: "Dispersante, adherente (Alcohol Graso Sulfatado)",
          porcetanje: "3.00%",
        },
      ],
    },
    {
      nombre: "Adher 30 SL",
      color: "#46947D",
      extras: "Adherente, dispersante, penetrante",
      generalidades:
        "Adherente que posee propiedades dispersantes y penetrantes, autorizado para su uso en la agricultura orgánica. Por sus propiedades es compatible con la mayoría de los plaguicidas y fertilizantes foliares de uso común.",
      composicion: [
        {
          componente: "Alcohol Polivinílico modificado",
          porcetanje: "20.00%",
        },
        {
          componente: "Dodecil Sulfato de Sodio",
          porcetanje: "10.00%",
        },
      ],
    },
    {
      nombre: "Surfaplus 28.7 SL",
      color: "#46947D",
      extras: "Dispersante, humectante, penetrante y antiespumante",
      generalidades:
        "Solución concentrada especialmente formulada con agentes dispersantes, humectantes, penetrantes y antiespumante. Para mejorar la eficiencia de los fertilizantes líquidos, fungicidas, insecticidas y herbicidas.",
      composicion: [
        {
          componente: "Dodecil Bencen Sulfonato de Sodio",
          porcetanje: "27.00%",
        },
        {
          componente: "Dimetil Polixiloxano",
          porcetanje: "1.70%",
        },
        {
          componente: "Inertes",
          porcetanje: "71.3%",
        },
      ],
    },
    {
      nombre: "Potenzoil 85 SL",
      color: "#46947D",
      extras: "Encapsulante",
      generalidades:
        "Coadyuvante a base de aceite vegetal que forma una capa protectora sobre la molécula del agroquímico, evitando que este pueda estar expuesto a factores abióticos como: viento, temperaturas altas y una posible degradación por la radiación solar incidente. El producto presenta característica de ser emulsionante, penetrante y encapsulan, haciendo las aplicaciones fitosanitarias más efectivas, con mayores periodos de control.",
      composicion: [
        {
          componente: "Aceites vegetales de Soya",
          porcetanje: "85.00%",
        },
      ],
    },
    {
      nombre: "Kapser 88 SL",
      color: "#46947D",
      extras: "Encapsulante",
      generalidades:
        "Coadyuvante a base de aceite vegetal que posee propiedades adherentes, emulsionantes, encapsulantes y antievaporante que permiten que el ingrediente activo de los agroquímicos llegue de manera eficiente a su sitio de acción.",
      composicion: [
        {
          componente: "Aceites vegetales de Soya",
          porcetanje: "88.00%",
        },
      ],
    },
    {
      nombre: "Dew - Max 90 SL",
      color: "#46947D",
      extras: "Encapsulante",
      generalidades:
        "Es un aceite vegetal que tiene la capacidad de formar una capa protectora sobre la molécula del agroquímico, evitando que este pueda estar expuesto a factores abióticos adversos como viento, temperaturas altas o radiación solar incidente. Su formulación permite obtener buenas emulsiones, además de mejorar la capacidad penetrante y dispersante de las mezclas, haciendo las aplicaciones fitosanitarias más efectivas.",
      composicion: [
        {
          componente: "Aceites vegetales de Soya",
          porcetanje: "90.00%",
        },
      ],
    },
    {
      nombre: "Emurall 85 SL",
      color: "#46947D",
      extras: "Emulsificante",
      generalidades:
        "Es un excelente emulsificante para la preparación de emulsiones a base de aceite y agua, disminuye la tensión superficial del agua favoreciendo la incorporación de agroquímicos a la mezcla, en especial las formulaciones con base aceite, dándoles una estabilidad a la emulsión por tiempos prolongados.",
      composicion: [
        {
          componente: "Alcohol Graso Etoxilado",
          porcetanje: "85.00%",
        },
        {
          componente: "Estabilizadores",
          porcetanje: "15.00%",
        },
      ],
    },
    // HIDROSOLUBLES
    {
      nombre: "N-Potenz",
      color: "#15337D",
      extras: "Fertilizante hidrosoluble",
      generalidades:
        "Fertilizante soluble en agua, es una fuente de Nitrógeno (N) que contiene Nitrógeno Nítrico y Amoniacal ideal para utilizarse en cualquier programa de fertilización, es un elemento esencial para todas las plantas en la etapa de desarrollo, esencial para la formación de aminoácidos, proteínas y ácidos nucleicos, este producto puede utilizarse en cultivos hortícolas, frutales, cereales, café, berries y ornamentales.",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "40.00%",
        },
        {
          componente: "Dextrina",
          porcetanje: "4.00%",
        },
      ],
    },
    {
      nombre: "Hidrobor",
      color: "#15337D",
      extras: "Fertilizante hidrosoluble",
      generalidades:
        "Fertilizante soluble en agua, proporciona a los cultivos el requerimiento nutricional de Boro (B). El producto puede ser aplicado desde la fase de plántula hacia la fase de prefloración y floración. La formulación puede ser empleada en variedad de cultivos como: ajo, cebolla, chile, tomate, brócoli, ejote, aguacate, pepino, papa, repollo, lechuga, frijol, melón, sandía, café, caña de azúcar, entre otros.",
      composicion: [
        {
          componente: "Boro (B₂O₃)",
          porcetanje: "44.80%",
        },
        {
          componente: "Nitrógeno (N)",
          porcetanje: "9.96%",
        },
      ],
    },
    {
      nombre: "10-40-10+AH+EM",
      color: "#15337D",
      extras: "Fertilizante hidrosoluble",
      generalidades:
        "Fertilizante soluble en agua, compuesto por Nitrógeno (N), alto contenido de Fósforo (P₂O₅) y Potasio (K₂O) recomendado fundamental para las etapas de siembra o trasplante, el cual favorece el desarrollo radicular de los cultivos, además en su formulación contiene microelementos, aminoácidos y ácidos húmicos libres, que lo hace un fertilizante completo para cultivos hortícolas, frutales, café, berries y ornamentales. Es un producto bajo en sodio y cloruro.",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "10.00%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "40.00%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "40.00%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "0.02%",
        },
        {
          componente: "Hierro (Fe)",
          porcetanje: "0.01%",
        },
        {
          componente: "Aminoácidos",
          porcetanje: "0.10%",
        },
        {
          componente: "Húmicos",
          porcetanje: "0.5%",
        },
        {
          componente: "Zinc (Zn)",
          porcetanje: "0.01%",
        },
        {
          componente: "Cobalto (Co)",
          porcetanje: "0.0002%",
        },
        {
          componente: "Niquel (Ni)",
          porcetanje: "0.002%",
        },
        {
          componente: "Azufre (S)",
          porcetanje: "0.05%",
        },
        {
          componente: "Molibdeno (Mo)",
          porcetanje: "0.0025%",
        },
        {
          componente: "Magnesio (Mg)",
          porcetanje: "0.011%",
        },
      ],
    },
    {
      nombre: "20-20-20",
      color: "#15337D",
      extras: "Fertilizante hidrosoluble",
      generalidades:
        "Fórmula hidrosoluble equilibrada el cual favorece el crecimiento en biomasa, raíces y frutos por su formulación balanceada, promueve el crecimiento y desarrollo de las plantas. Ayuda en la etapa de floración y cuajado a corregir las deficiencias nutricionales en las plantas.",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "20.00%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "20.00%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "20.00%",
        },
        {
          componente: "Magnesio (MgO)",
          porcetanje: "0.01%",
        },
        {
          componente: "Azufre (S)",
          porcetanje: "0.05%",
        },
        {
          componente: "Calcio (CaO)",
          porcetanje: "0.125%",
        },
        {
          componente: "Zinc (Zn)",
          porcetanje: "0.01%",
        },
        {
          componente: "Hierro (Fe)",
          porcetanje: "0.01%",
        },
        {
          componente: "Cobre (Cu)",
          porcetanje: "0.10%",
        },
        {
          componente: "CBoro (B)",
          porcetanje: "0.02%",
        },
        {
          componente: "Aminoácidos",
          porcetanje: "0.10%",
        },
        {
          componente: "Manganeso (Mn)",
          porcetanje: "1.50%",
        },
        {
          componente: "Húmicos",
          porcetanje: "0.20%",
        },
        {
          componente: "Fúlvicos",
          porcetanje: "0.10%",
        },
        {
          componente: "Molibdeno (Mo)",
          porcetanje: "0.0025%",
        },
        {
          componente: "Níquel (Ni)",
          porcetanje: "0.0020%",
        },
        {
          componente: "Cobalto (Co)",
          porcetanje: "0.0020%",
        },
      ],
    },
    {
      nombre: "10-10-40+AH+EM",
      color: "#15337D",
      extras: "Fertilizante hidrosoluble",
      generalidades:
        "Fórmula hidrosoluble equilibrada que contiene Nitrógeno (N), Fósforo (P₂O₅) y alto contenido de Potasio (K₂O) el cual es fundamental para la etapa de floración y fructificación, también incrementa los grados Brix y engorde de la fruta.",
      composicion: [
        {
          componente: "Nitrógeno (N)",
          porcetanje: "10.00%",
        },
        {
          componente: "Fósforo (P₂O₅)",
          porcetanje: "10.00%",
        },
        {
          componente: "Potasio (K₂O)",
          porcetanje: "10.00%",
        },
        {
          componente: "Boro (B)",
          porcetanje: "0.02%",
        },
        {
          componente: "Hierro (Fe)",
          porcetanje: "0.01%",
        },
        {
          componente: "Aminoácidos",
          porcetanje: "0.10%",
        },
        {
          componente: "Húmicos",
          porcetanje: "0.5%",
        },
        {
          componente: "Zinc (Zn)",
          porcetanje: "0.01%",
        },
        {
          componente: "Cobalto (Co)",
          porcetanje: "0.0002%",
        },
        {
          componente: "Niquel (Ni)",
          porcetanje: "0.002%",
        },
        {
          componente: "Azufre (S)",
          porcetanje: "0.05%",
        },
        {
          componente: "Molibdeno (Mo)",
          porcetanje: "0.0025%",
        },
        {
          componente: "Magnesio (Mg)",
          porcetanje: "0.011%",
        },
      ],
    },
    {
      nombre: "K Humus",
      color: "#15337D",
      extras: "Fertilizante hidrosoluble",
      generalidades:
        "Fertilizante hidrosoluble con alto contenido de Potasio (K₂O), elemento fundamental para el desarrollo y engorde del fruto, también es el encargado de los procesos fisiológicos como: Osmoregulación en la planta mediante la apertura y cierre estomático, brindándole tolerancia al cultivo ante situaciones de sequía y salinidad, adicional el Potasio (K₂O), colabora en procesos como la síntesis de almidones, la activación de enzimas y la síntesis de proteínas.",
      composicion: [
        {
          componente: "Potasio (K₂O)",
          porcetanje: "10.10%",
        },
        {
          componente: "Húmicos",
          porcetanje: "2.00%",
        },
      ],
    },
    {
      nombre: "Potenz Blend",
      color: "#15337D",
      extras: "Fertilizante hidrosoluble",
      generalidades:
        "Es un producto altamente concentrado en ácidos húmicos, fúlvicos, algas marinas y aminoácidos. Mejora la estructura, retención y aireación del suelo, mejorando la capacidad de intercambio catiónico, bioestiumulacion radicular.",
      composicion: [
        {
          componente: "Potasio (K₂O)",
          porcetanje: "10.00%",
        },
        {
          componente: "Ácidos húmicos y fúlvicos",
          porcetanje: "64.00%",
        },
        {
          componente: "Extracto de algas",
          porcetanje: "10.00%",
        },
        {
          componente: "Aminoácidos",
          porcetanje: "8.00%",
        },
      ],
    },
  ];

  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filteredProducts = productos.filter((producto) =>
      producto.nombre.toLowerCase().includes(term)
    );
    setSearchResults(filteredProducts);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <nav
      className="navbar navbar-expand-lg fixed-top"
      style={{
        backgroundColor: `${isSticky ? "white" : "transparent"}`,
        animation: `${
          isSticky
            ? "colorChange 0.3s ease"
            : isSticky === false
            ? "colorChangeBack 0.3s ease"
            : ""
        }`,
      }}
    >
      <div className="container-fluid">
        <a
          className="navbar-brand"
          href="/#"
          style={{ color: `${isSticky ? "black" : "white"}` }}
        >
          {isSticky ? (
            <img
              src={logoPoten}
              alt="Logo"
              width="128"
              height="auto"
              className="d-inline-block align-text-top"
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 5,
              }}
            />
          ) : (
            <img
              src={logoPotenehite}
              alt="Logo"
              width="128"
              height="auto"
              className="d-inline-block align-text-top"
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 5,
              }}
            />
          )}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ backgroundColor: `${isSticky ? "white" : "white"}` }}
        >
          <span
            className="navbar-toggler-icon"
            style={{ color: `${isSticky ? "white" : "white"}` }}
          ></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className="nav-link active regular"
                aria-current="page"
                to={"/"}
                style={{ color: `${isSticky ? "black" : "white"}` }}
              >
                {t("Inicio")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/quiene-somos"}
                className="nav-link active regular"
                aria-current="page"
                style={{ color: `${isSticky ? "black" : "white"}` }}
              >
                {t("Quienes_somos")}
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                to={"/#"}
                className="nav-link dropdown-toggle regular"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ color: `${isSticky ? "black" : "white"}` }}
              >
                {t("Catalogo")}
              </Link>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <Link
                    className="dropdown-item regular"
                    to={"/agrinutrientes"}
                  >
                    <img
                      src={agrinutriente_img}
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="especialidades"
                    />{" "}
                    {t("Agrinutrientes")} &raquo;
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item regular"
                    to={"/especialidades"}
                  >
                    <img
                      src={especialidades_img}
                      loading="lazy"
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="agrinutrientes"
                    />{" "}
                    {t("Especialidades")} &raquo;
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item regular"
                    to={"/bioestimulantes"}
                  >
                    <img
                      src={bioestimulante_img}
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="bioestimulante_img"
                    />{" "}
                    {t("Bioestimulantes")} &raquo;
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item regular"
                    to={"/bioprotectores"}
                  >
                    <img
                      src={bioprotectores_img}
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="bioprotectores_img"
                    />{" "}
                    {t("Bioprotectores")} &raquo;
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item regular" to={"/correctoresAS"}>
                    <img
                      src={correctore_img}
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="correctore_img"
                    />{" "}
                    {t("Correctores_de_agua_y_suelo")} &raquo;
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item regular" to={"/coadyuvantes"}>
                    <img
                      src={coadyuvantes}
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="coadyuvantes"
                    />{" "}
                    {t("Coadyuvantes")} &raquo;
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item regular" to={"/hidrosolubles"}>
                    <img
                      src={hidrosolubles}
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="coadyuvantes"
                    />{" "}
                    {t("Hidrosolubles")} &raquo;
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link active regular"
                aria-current="page"
                to={"/distribuidores"}
                style={{ color: `${isSticky ? "black" : "white"}` }}
              >
                {t("Distribuidores")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/contactanos"}
                className="nav-link active regular"
                aria-current="page"
                style={{ color: `${isSticky ? "black" : "white"}` }}
              >
                {t("Contactanos")}
              </Link>
            </li>
          </ul>

          {/* Dropdown de búsqueda */}
          <div className="dropdown ml-auto">
            <input
              id="searchInput"
              className="form-control"
              type="search"
              placeholder="Buscar por nombre"
              aria-label="Buscar"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={searchTerm}
              onChange={handleSearch}
            />
            {searchTerm !== "" ? (
              <div className="dropdown-menu show" aria-labelledby="searchInput">
                {searchResults.map((product, index) => (
                  <Link
                    to={`/producto/${product.nombre}`}
                    key={index}
                    className="dropdown-item"
                    onClick={() => {
                      console.log("Dimos clik");
                      setSearchResults([]);
                      setSearchTerm("");
                    }}
                  >
                    <strong>{product.nombre}</strong>
                    <br />
                    {product.descripcion}
                  </Link>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
